import { Auth } from "aws-amplify";
import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { Link, withRouter } from "react-router-dom";

import "./App.css";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import Routes from "./Routes";

import 'react-toastify/dist/ReactToastify.min.css';
import 'react-datetime/css/react-datetime.css';
import 'react-image-lightbox/style.css';


function App(props) {
  async function onLoad() {
  }

  useEffect(() => {
    onLoad();
  }, []);

  return (
    <div className="App">
      <Routes appProps={{
        }} />

    </div>
  );
}

export default withRouter(App);

App.propTypes = {
  history: PropTypes.object,
}
