import PropTypes from "prop-types";
import React from "react";
import { Route, Switch } from "react-router-dom";

import AppliedRoute from "./components/AppliedRoute";

import Home from "./containers/Home";
import NotFound from "./containers/NotFound";
import ReferralLanding from "./containers/ReferralLanding";


export default function Routes({ appProps }) {
  return (
    <Switch>
      <AppliedRoute path="/:path" exact component={Home} appProps={appProps} />
      <AppliedRoute path="/r/:slug" component={ReferralLanding} appProps={appProps} />
      <AppliedRoute path="/" exact component={Home} appProps={appProps} />


      { /* Finally, catch all unmatched routes */ }
      <Route component={NotFound} />
    </Switch>
  );
}

Routes.propTypes = {
  appProps: PropTypes.object,
}
