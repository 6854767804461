import PropTypes from "prop-types";
import React from "react";
import { Route } from "react-router-dom";


export default function AppliedRoute({ component: C, appProps, ...rest }) {
  return (
    <Route {...rest} render={props => <C {...props} {...appProps} />} />
  );
}

AppliedRoute.propTypes = {
  component: PropTypes.func,
  appProps: PropTypes.object,
}
