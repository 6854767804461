import React, { useEffect } from "react";
import {
  useParams,
} from "react-router-dom";

export default function Home() {
  const { slug } = useParams();
  // One-time load of previous Stripe charges
  useEffect(() => {
    if (slug == null) {
      window.location.replace('https://kovocredit.com/');
    } else {
      window.location.replace(`https://kovocredit.com/r/${slug}`);
    }
  }, []);

  return (
    <p>Redirecting...</p>
  );
}
