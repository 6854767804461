import Amplify from 'aws-amplify';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';


import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';

import './index.css';
import App from './App';

import Main from './Main';
import './helpers/initFA';


Amplify.configure({
  // Auth: {
  //   mandatorySignIn: true,
  //   region: config.cognito.REGION,
  //   userPoolId: config.cognito.USER_POOL_ID,
  //   identityPoolId: config.cognito.IDENTITY_POOL_ID,
  //   userPoolWebClientId: config.cognito.APP_CLIENT_ID
  // },
  // // Storage: {
  // //   region: config.s3.REGION,
  // //   bucket: config.s3.BUCKET,
  // //   identityPoolId: config.cognito.IDENTITY_POOL_ID
  // // },
  // API: {
  //   endpoints: [
  //     {
  //       name: "installments",
  //       endpoint: config.apiGateway.URL,
  //       region: config.apiGateway.REGION
  //     },
  //   ]
  // }
});

ReactDOM.render(
  <Main>
  <Router>
    <App />
  </Router>
  </Main>,
  document.getElementById('main')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
