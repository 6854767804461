import React, { useEffect } from "react";
import {
  useParams,
} from "react-router-dom";

export default function Home(props) {
  const { path } = useParams();
  // One-time load of previous Stripe charges
  useEffect(() => {
    if (path == null) {
      window.location.replace('https://kovocredit.com/');
    } else {
      window.location.replace(`https://kovocredit.com/${path}`);
    }
  }, []);

  return (
    <p>Redirecting...</p>
  );
}
